<template>
  <div class="all">
    <!-- 新建按钮 -->
    <el-button class="new" @click="newbtn">+ 新建</el-button>
    <!-- 表格内容展示 -->
    <el-table :data="tableList">
      <el-table-column type="selection" align="center">
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="questionName" label="问题">
      </el-table-column>

      <el-table-column header-align="center" align="center" prop="updateTime" label="修改时间">
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="adminName" label="创建人">
      </el-table-column>

      <el-table-column header-align="center" align="center" prop="status" label="状态">
        <template slot-scope="{row}">
          {{row.status==1? '上线':'下线'}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="prop" label="操作">
        <template slot-scope="{row}">
          <!-- <el-button class="cha1" @click="detailIn(row.id)">查看</el-button> -->
          <el-button class="edit1" @click="edit(row.id)">修改</el-button>
          <el-button class="del1" @click="del(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="title" :visible.sync="banner" @close="closeDialog" :close-on-click-modal="false">
      <el-form ref="form" :model="formD" label-width="120px" :rules="rules">
        <el-form-item label="问题 : " prop="questionName">
          <el-input v-model="formD.questionName" placeholder="请输入问题" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="回答 : " class="editerf">

          <!-- //富文本 -->
          <!-- <editor :formD="formD" /> -->
          <!-- <quill-editor v-model="formD.answer" ref="myQuillEditor" class="editer"></quill-editor> -->
          <div class="quill-wrap">
            <input class="quill-img" type="file" @change="uploade($event)"
              style="position: fixed; top: -9999px; left: -9999px;" />

            <quill-editor v-model="formD.answer" ref="myQuillEditor" :options="editorOption" class="ql-editor">
            </quill-editor>
          </div>
        </el-form-item>
        <el-form-item label="状态 : " prop="status" class="status">
          <el-radio-group v-model="formD.status" @change="statuschange">
            <el-radio label="1">上线</el-radio>
            <el-radio label="0">下线</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="btn">
          <el-button class="cancelBtn" @click="cancel">取消</el-button>
          <el-button class="savebtn" @click="savebanner" :disabled="savebtn">保存</el-button>
        </el-form-item>

      </el-form>

    </el-dialog>
    <el-pagination background layout="prev, pager, next,total" :total="page.total" :page-size="page.pageSize"
      :current-page.sync="page.pageNum" @current-change="handleGetList">
    </el-pagination>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { uploadImg } from '@/api/upload'
import store from '@/store'
// import editor from '@/components/editor'
import { createQa, deleteQa, getDetailQa, getQaList, modifyQa } from '../../../api/aboutus'
export default {
  data () {
    return {
      savebtn: false,//防抖使用

      content: '',
      // 富文本框参数设置
      editorOption: {
        modules: {
          // ImageExtend: {
          //   loading: true,
          //   name: 'file',
          //   action: 'http://api.mz-biotech.com/api/file/uploadFiles',
          //   headers: (xhr) => {
          //     xhr.setRequestHeader('Authorization', store.getters.token)
          //   },
          //   response: (res) => {
          //     return res.data
          //   }
          // },
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
              ["blockquote", "code-block"], //引用，代码块
              [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
              [{ list: "ordered" }, { list: "bullet" }], //列表
              [{ script: "sub" }, { script: "super" }], // 上下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              [{ direction: "rtl" }], // 文本方向
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
              [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
              [{ font: [] }], //字体
              [{ align: [] }], //对齐方式
              ["clean"], //清除字体样式
              ["image", "video"], //上传图片、上传视频
            ],
            handlers: {
              'image': function () {
                document.querySelector(".quill-img").click();
              }
            }
          }
        }
      },
      //控制编辑和新建弹框的显示隐藏
      banner: false,
      //控制查看的弹框显示隐藏
      lookbanner: false,
      //banner表格的数据
      tableList: [],
      //编辑图片的
      showimg: true,
      showimgw: true,
      //每一条banner的数据
      formD: {

        adminName: '',//	创建人名
        id: 0,
        answer: '',//答案
        questionName: '',//问题名称
        status: null,//	状态：0下线 1上线

      },

      orderNum: null,//排序的长度
      //弹框的标题
      title: '新建问题',
      //表格的页码
      page: {
        total: null,
        pageNum: 1,
        pageSize: 10
      },
      rules: {
        categoryName: [
          { required: true, message: 'Banner名称不能为空', trigger: 'blur' },
          { max: 16, message: '长度不多于16个字符', trigger: 'change' }
        ],
        orderd: [
          { required: true, message: '排序不能为空', trigger: 'change' }
        ],
        status: [
          { required: true, message: '状态不能为空', trigger: 'change' }
        ]

      }
    }
  },
  components: {
    // editor
    quillEditor
  },
  computed: {
    headers () {

      return {
        "Authorization": store.getters.token
      }

    }
  },
  created () {
    this.getbannerlist()
  },
  methods: {
    async uploade (e) {
      const files = e.target.files;

      const res = await uploadImg(files[0])

      let quill = this.$refs.myQuillEditor.quill;  //获取富文本编辑器的编辑框
      quill.focus();     //聚焦在上面
      quill.insertEmbed(quill.getSelection().index, "image", res.data);

      document.querySelector(".quill-img").value = "";
    },
    //页码控制
    handleGetList () {
      this.tableList = []
      this.getbannerlist()
    },
    // 处理时间
    gettime (timestamp, gettype) {
      // console.log(timestamp, 'timestamp')
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      if (gettype == "yyddmm") {
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10 ?
            "0" + (date.getMonth() + 1) :
            date.getMonth() + 1) + "-";
        var D = date.getDate() < 10 ? "0" + date.getDate() + ' ' : date.getDate() + " ";
        var h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
        var m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":"
        var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
      }
      // 算时间差
      if (gettype == "getcha") {
        // 两个时间戳相差的秒数
        var usedTime = timestamp * 1000;
        // console.log(usedTime,'usedTime')
        var hours = Math.floor(usedTime / (3600 * 1000));
        // var leave1 = usedTime % (3600 * 1000);
        // var minutes = Math.floor(leave1 / (60 * 1000))
      }
      return {
        yymmdd: Y + M + D + h + m + s,
        timecha: hours,
      };
    },
    async getbannerlist () {
      const { count, obj } = await getQaList(this.page)

      obj.map(item => {
        item.updateTime = (this.gettime(item.updateTime, "yyddmm").yymmdd)
      })
      this.page.total = count
      this.tableList = obj


    },
    //点击新建按钮
    newbtn () {
      this.banner = true
      this.title = '新建问题'
      this.formD.num = this.title == '新建问题' ? this.page.total + 1 : this.page.total

    },
    //点击修改按钮
    async edit (id) {

      const res = await getDetailQa({ id })
      res.status = res.status + ''
      this.formD = res

      this.title = '修改问题'
      this.formD.num = this.title == '新建问题' ? this.page.total + 1 : this.page.total
      this.showimg = true
      this.showimgw = true
      this.banner = true

    },

    //点击删除按钮
    async del (id) {
      await this.$confirm('是否确认删除,删除后的数据将不能再恢复!')
      await deleteQa({ id })
      this.getbannerlist()
    },
    //本地文件上传成功时-展示图

    uploadSuccess (file) {
      console.log(file);
      this.formD.pcPic = file.data
    },
    //本地文件上传成功时-检测报告文件

    upload (file) {
      this.formD.wapPic = file.data
    },
    // 点击保存编辑或新建问题
    async savebanner () {
      await this.$refs.form.validate()
      this.savebtn = true
      setTimeout(() => this.savebtn = false, 2000)

      if (this.title == '新建问题') {
        await createQa(this.formD)
        this.$message.success('新建问题成功')
        this.getbannerlist()
        this.closeDialog()
      } else if (this.title = '修改问题') {

        await modifyQa(this.formD)
        this.$message.success('修改问题成功')
        this.getbannerlist()
        this.closeDialog()
      }

    },
    //关闭弹窗x
    closeDialog () {
      this.$refs.form.resetFields()
      this.formD = {}
      // this.formD.pcPic = ''
      // this.formD.wapPic = ''
      // this.formD.forwardHead = ''
      this.banner = false
    },
    //点击取消时
    cancel () {
      this.closeDialog()
    },
    //当页面选择框改变时
    async selchange (v) {
      const res = await getproductType({ forwardHead: v })
      // res.map((item, i) => {

      //   this.producttype.push({ value: item.id, label: item.name })
      // })
      this.producttype = res
    },




    //查看详情弹框关闭
    detailclose () {
      this.lookbanner = false
    },

    //状态改变判断上线
    async statuschange () {
      const res = await checkshangxian({ bannerType: 1 })
      if (!res && this.formD.status == 1) {
        this.$confirm('上线条数已达上限,请下线其他banner再进行该操作!')

      }

    },

  }
}
</script>

<style lang="scss" scoped>
.new {
  margin-right: 100%;
}
.new.el-button {
  display: inline-block;
  width: 100px;
  height: 32px;
  background: #4a71af;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 6px;
  letter-spacing: 2px;
  margin-top: 24px;
}

.upload-demo1 {
  position: relative;
  top: -28px;
  left: 50px;
}
::v-deep .el-upload-list__item {
  width: 50%;
  position: absolute;
  top: 0;
  left: 35%;
}
.upload {
  text-align: left;
}
.paixu {
  text-align: left;
  ::v-deep .el-input__inner {
    width: 80px;
    height: 40px;
    background: #ffffff;
    // box-shadow: inset 0px 1px 3px 0px rgba(74, 113, 175, 1);
    border-radius: 12px;
    border: 2px solid rgba(74, 113, 175, 1);
    color: rgba(74, 113, 175, 1);
  }
}
.status {
  text-align: left;
}

.btn {
  .cancelBtn {
    margin-right: 50px;
    width: 110px;
    height: 40px;
    background: #ffffff;
    border-radius: 12px;
    border: 2px solid #4a71af;
    ::v-deep span {
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4a71af;
      line-height: 10px;
    }
  }
  .savebtn {
    width: 110px;
    height: 40px;
    background: #4a71af;
    border-radius: 12px;
    ::v-deep span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 10px;
    }
  }
}
::v-deep .el-form-item__label {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #273352;
  text-align: end;
  line-height: 38px;
  letter-spacing: 1px;
}

::v-deep .el-input__inner {
  width: 300px;
  height: 40px;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid rgba(74, 113, 175, 1);
  color: rgba(74, 113, 175, 1);
}
::v-deep .el-dialog__header {
  margin-left: 5%;
}
.formimg {
  vertical-align: top;
  position: relative;
  left: 20px;
  width: 220px;
  height: 144px;
}
.delimg {
  position: relative;
  top: -20px;
  right: -300px;
  display: block;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a71af;
  line-height: 17px;
  margin-bottom: 20px;
  text-decoration: underline;
}
.chaimg {
  vertical-align: top;
  position: relative;
  left: 27px;
  margin-bottom: 20px;
  width: 200px;
  height: 150px;
}
.chaimg1 {
  vertical-align: top;
  position: relative;
  left: 15px;
  margin-bottom: 20px;
  width: 200px;
  height: 150px;
}
::v-deep .el-form-item__content {
  text-align: left;
}
.bannerdetail {
  ::v-deep .el-form-item__label {
    width: 104px;
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #273352;
    line-height: 42px;
    letter-spacing: 1px;
  }
  // ::v-deep .el-form-item__content {
  //   text-align: left;
  // }
  .neirong {
    width: 240px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4a71af;
    line-height: 20px;
    letter-spacing: 1px;
  }
  ::v-deep .el-button {
    width: 155px;
    height: 40px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #4a71af;
    font-weight: 500;
    color: #4a71af;
  }
  .orderd {
    display: inline-block;
    width: 40px;
    height: 40px;
    color: #4a71af;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #4a71af;
    text-align: center;
  }
  .img {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #273352;
    letter-spacing: 1px;
  }
}

.edit1 {
  width: 68px;
  height: 28px;
  background: #16b394;
  color: #fff;
  line-height: 2px;
  border: 0;

  border-radius: 8px;
}
.del1 {
  width: 68px;
  height: 28px;
  background: #e25858;
  border-radius: 8px;
  color: #fff;
  line-height: 2px;
  border: 0;
}
::v-deep .el-table__header-wrapper {
  margin-top: 15px;
  // width: 1610px;
  height: 60px;
  line-height: 35px;
  background: #f2f2f2;
  border-radius: 20px 20px 0px 0px;

  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  th.el-table__cell {
    background: #f2f2f2;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
  }
}
.el-table__cell {
  width: 48px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #4a4a4a;
  line-height: 17px;
}
.tiaozhuan {
  ::v-deep .el-input__inner {
    width: 180px;
    height: 40px;
    margin-right: 10px;
    background: #ffffff;
    border-radius: 12px;
    border: 2px solid rgba(74, 113, 175, 1);
    color: rgba(74, 113, 175, 1);
  }
  ::v-deep .el-input__suffix {
    position: absolute;
    left: 140px;
  }
}
::v-deep .el-upload-list__item {
  position: relative;
  top: -130px;
  left: 260px;
}
.editerf {
  height: 600px;
  .editer {
    height: 500px;
  }
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #4a71af;
}
.quill-editor {
  height: 400px;
  min-height: 400px;
}
.ql-container {
  overflow: auto;
}
</style>