import request from "../utils/request";
//获取团队成员列表
export const getemployeelist = data => {
  return request({
    method: 'POST',
    url: '/system/about/getMember',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//新建团队成员
export const createemployee = data => {
  return request({
    method: 'POST',
    url: '/system/about/createMember',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除成员
export const delemplyee = data => {
  return request({
    method: 'POST',
    url: '/system/about/deleteTeam',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取团队成员详情
export const getemplyeeDetail = data => {
  return request({
    method: 'POST',
    url: '/system/about/getDetailMember',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改团队成员
export const changeemployee = data => {
  return request({
    method: 'POST',
    url: '/system/about/modifyTeam',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
// ======分割线=====专家顾问
//新建专家
export const createExpert = data => {
  return request({
    method: 'POST',
    url: '/system/about/createExpert',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除专家
export const deleteExpert = data => {
  return request({
    method: 'POST',
    url: '/system/about/deleteExpert',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取专家顾问详情
export const getDetailExpert = data => {
  return request({
    method: 'POST',
    url: '/system/about/getDetailExpert',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取专家顾问列表
export const getExpertList = data => {
  return request({
    method: 'POST',
    url: '/system/about/getExpert',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改专家
export const modifyExpert = data => {
  return request({
    method: 'POST',
    url: '/system/about/modifyExpert',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
// ======分割线======合作案例
//新建案例
export const createCase = data => {
  return request({
    method: 'POST',
    url: '/system/about/createCase',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除案例
export const deleteCase = data => {
  return request({
    method: 'POST',
    url: '/system/about/deleteCase',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取合作案例列表
export const getCaseList = data => {
  return request({
    method: 'POST',
    url: '/system/about/getCase',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取合作案例详情
export const getDetailCase = data => {
  return request({
    method: 'POST',
    url: '/system/about/getDetailCase',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改案例
export const modifyCase = data => {
  return request({
    method: 'POST',
    url: '/system/about/modifyCase',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
// =====分割线======百问百答
//新建问答
export const createQa = data => {
  return request({
    method: 'POST',
    url: '/system/about/createQa',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}
//删除问答
export const deleteQa = data => {
  return request({
    method: 'POST',
    url: '/system/about/deleteQa',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取问答详情
export const getDetailQa = data => {
  return request({
    method: 'POST',
    url: '/system/about/getDetailQa',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//获取问答列表
export const getQaList = data => {
  return request({
    method: 'POST',
    url: '/system/about/getQa',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}
//修改问答
export const modifyQa = data => {
  return request({
    method: 'POST',
    url: '/system/about/modifyQa',
    data,
    headers: {
      'CustomRequestType': 'application/json;charset=UTF-8'
    }

  })
}